<template>
  <TabsMenuRef :types="types" v-model="typeActive" v-if="types"/>
  <template v-for="type in activeTypes" >
    <Page :type="type" v-if="mainStore().typeProfile" v-show="typeActive == type"/>
    <Page :type="type" v-else/>
  </template>

</template>

<script>
import Page from "@/pages/Referrals/page.vue";
import TabsMenuRef from "@/components/tabsMenuRef.vue";
import {mainStore, mainTypes} from "@/store/main";
import {getReferralsApi} from "@/api/referral";

export default {
  name: "index",
  components: {Page, TabsMenuRef},
  data() {
    return {
      activeTypes: [],
      typeActive: null,
      types: null
    }
  },
  mounted() {
    this.checkData()
  },
  watch: {
    typeActive() {
      if (!this.activeTypes.includes(this.typeActive)) {
        this.activeTypes.push(this.typeActive)
      }
    }
  },
  computed: {},
  methods: {
    mainStore,
    async checkData() {
      if (mainStore().typeProfile && mainStore().typeProfile.length !== 2) {
        let types = ['rs', 'cpa', 'hybrid']
        const currentType = mainStore().typeProfile[0].id
        const index = types.indexOf(currentType)
        types.splice(index, 1)
        types.unshift(currentType)
        for (let type of types) {
          const data = await this.getReferrals(type)
          if (data.length) {
            const findType = mainTypes.find(t => t.id == type)
            this.types = [findType]
            this.activeTypes.push(type)
            return
          }
        }
        // this.typeActive = currentType
        const findType = mainTypes.find(t => t.id == currentType)
        this.types = [findType]
        this.activeTypes.push(currentType)
      } else if (mainStore().typeProfile) {
        this.activeTypes.push(mainStore().typeProfile[0].id)
      } else {
        let types = ['rs', 'cpa', 'hybrid']
        for (let type of types) {
          const data = await this.getReferrals(type)
          if (data.length) {
            const findType = mainTypes.find(t => t.id == type)
            this.types = [findType]
            this.activeTypes.push(type)
            return
           } else this.activeTypes = ['']
        }
      }
      this.types = mainStore().typeProfile
    },
    getReferrals(type) {
      return getReferralsApi({}, type).then((resp) => {
        return resp.data.items
      }, (error) => {
        console.log(error)
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>
